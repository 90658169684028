import { api, h } from '../helpers/index';
import * as UserModel from '../models/user';
import * as CompanyModel from '../models/company';
import * as MemberModel from '../models/member';
import {company, general} from "./index";

/**
 * validates UEN of businesses in Singapore
 * https://www.uen.gov.sg/ueninternet/faces/pages/admin/aboutUEN.jspx
 * @param uen
 * @returns {boolean}
 */
function validateUENLocally (uen) {
    var debug = false;
    const entityTypeIndicator = [
        'LP', 'LL', 'FC', 'PF', 'RF', 'MQ', 'MM', 'NB', 'CC', 'CS', 'MB', 'FM', 'GS', 'GA',
        'GB', 'DP', 'CP', 'NR', 'CM', 'CD', 'MD', 'HS', 'VH', 'CH', 'MH', 'CL', 'XL', 'CX',
        'RP', 'TU', 'TC', 'FB', 'FN', 'PA', 'PB', 'SS', 'MC', 'SM'
    ];

    if (debug) {
        console.log('(A) Businesses registered with ACRA');
        console.log('(B) Local companies registered with ACRA');
        console.log('(C) All other entities which will be issued new UEN');
    }

    // check that uen is not empty
    if (!uen || String(uen) === '') {
        if (debug) { console.log('UEN is empty'); }
        return false;
    }

    // check if uen is 9 or 10 digits
    if (uen.length < 9 || uen.length > 10) {
        if (debug) { console.log('UEN is not 9 or 10 digits'); }
        return false;
    }

    uen = uen.toUpperCase();
    var uenStrArray = uen.split('');

    // (A) Businesses registered with ACRA
    if (uenStrArray.length === 9) {
        // check that last character is a letter
        if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
            if (debug) { console.log('(A) last character is not an alphabet'); }
            return false;
        }

        for (var i = 0; i < uenStrArray.length - 1; i++) {
            // check that first 8 letters are all numbers
            if (isNaN(uenStrArray[i])) {
                if (debug) { console.log('(A) there are non-numbers in 1st to 8th letters'); }
                return false;
            }
        }

        // (A) Businesses registered with ACRA (SUCCESS)
        if (debug) { console.log('valid (A) Businesses registered with ACRA'); }
        return true;
    }
    else if (uenStrArray.length === 10) {
        // check that last character is a letter
        if (!isNaN(uenStrArray[uenStrArray.length - 1])) {
            if (debug) { console.log('(B)(C) last character is not an alphabet'); }
            return false;
        }

        // (B) Local companies registered with ACRA
        if (!isNaN(uenStrArray[0]) && !isNaN(uenStrArray[1]) && !isNaN(uenStrArray[2]) && !isNaN(uenStrArray[3])) {
            // check that 5th to 9th letters are all numbers
            if (!isNaN(uenStrArray[4]) && !isNaN(uenStrArray[5]) && !isNaN(uenStrArray[6]) &&
                !isNaN(uenStrArray[7]) && !isNaN(uenStrArray[8])) {
                // (B) Local companies registered with ACRA (SUCCESS)
                if (debug) { console.log('valid (B) Local companies registered with ACRA'); }
                return true;
            } else {
                if (debug) { console.log('(B) there are non-numbers in 5th to 9th letters'); }
                return false;
            }
        }
        // (C) All other entities which will be issued new UEN
        else {
            // check that 1st letter is either T or S or R
            if (uenStrArray[0] !== 'T' && uenStrArray[0] !== 'S' && uenStrArray[0] !== 'R') {
                if (debug) { console.log('(C) 1st letter is incorrect'); }
                return false;
            }

            // check that 2nd and 3rd letters are numbers only
            if (isNaN(uenStrArray[1]) || isNaN(uenStrArray[2])) {
                if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                return false;
            }

            // check that 4th letter is an alphabet
            if (!isNaN(uenStrArray[3])) {
                if (debug) { console.log('(C) 4th letter is not an alphabet'); }
                return false;
            }

            // check entity-type indicator
            var entityTypeMatch = false,
                entityType = String(uenStrArray[3]) + String(uenStrArray[4]);
            for (var j = 0; j < entityTypeIndicator.length; j++) {
                if (String(entityTypeIndicator[j]) === String(entityType)) {
                    entityTypeMatch = true;
                }
            }
            if (!entityTypeMatch) {
                if (debug) { console.log('(C) entity-type indicator is invalid'); }
                return false;
            }

            // check that 6th to 9th letters are numebrs only
            if (isNaN(uenStrArray[5]) || isNaN(uenStrArray[6]) || isNaN(uenStrArray[7]) || isNaN(uenStrArray[8])) {
                if (debug) { console.log('(C) 2nd and 3rd letter is incorrect'); }
                return false;
            }

            // (C) All other entities which will be issued new UEN (SUCCESS)
            if (debug) { console.log('valid (C) All other entities which will be issued new UEN'); }
            return true;
        }
    }

    return false;
}

/**
 * validates email address
 * @param email
 * @returns {boolean}
 */
export function validateEmail (email) {
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i.test(email)) {
        return true;
    }
    return false;
}

/**
 * validate phone number
 * @param phone
 * @returns {boolean}
 */
export function validatePhone (phone) {
    if (/^\+?\d+$/i.test(phone)) {
        return true;
    }
    return false;
}

/**
 * validates email availability
 * @param email
 * @param callback
 */
export function validateEmailAvailability (email, callback) {
    var data = { email: email };
    UserModel.checkEmailAvailability(data, function (data) {
        var apiRes = api.handleApiResponse(data, false);
        if (apiRes.status === 'ok') {
            callback(true);
        } else {
            callback(false);
        }
    });
}

/**
 * validates members email availability
 * @param email
 * @param callback
 */
export function validateMembersEmailAvailability (email, callback) {
    const data = {
        email: email
    };
    MemberModel.checkMembersEmailAvailability(data, (data) => {
        var apiRes = api.handleApiResponse(data, false);
        if (apiRes.status === 'ok') {
            if (h.notEmpty(apiRes.data))
                callback(apiRes.data.result);
        }
    });
}

/**
 * check if email already exist in system
 * @param email
 * @param callback
 */
export function checkEmailExist(email, callback) {
    var data = { email: email };
    UserModel.checkEmailExist(data, function (data) {
        var apiRes = api.handleApiResponse(data, false);
        var userData = {};
        if (general.notEmpty(apiRes.data) && general.notEmpty(apiRes.data.user)) {
            userData = apiRes.data.user;
        }
        if (apiRes.status === 'ok') {
            callback(true, userData);
        } else {
            callback(false, userData);
        }
    });
}

/**
 * check for company home url availability
 * @param homeUrl
 * @param callback
 */
export function validateHomeUrl (homeUrl, callback) {
    if (general.isEmpty(homeUrl)) {
        return callback();
    }
    var data = { home_url: homeUrl };
    CompanyModel.checkHomeUrl(data, function (data) {
        var apiRes = api.handleApiResponse(data, false);
        if (apiRes.status === 'ok') {
            if (apiRes.data.suggested_url) {
                callback(apiRes.data.suggested_url)
            } else {
                callback();
            }
        }
    });
}

/**
 * checks that url is valid
 * @param url
 * @returns {*}
 */
export function validateUrl (url) {
    var pattern = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!-/]))?/;
    if (!pattern.test(url)) {
        return 'Please enter a valid URL';
    } else {
        return '';
    }
}
/**
 * check if variable is an integer
 * @param value
 * @returns {boolean}
 */
export function isVarInteger (value) {
    return !isNaN(value) && (value % 1 === 0);
};

/**
 * validates uen number based on country
 * @param countryCode
 * @param uen
 * @param callback
 * success = {
 *      status: true,
 *      result: {
 *          company_name: string,
 *          company_url_name: string
 *      }
 * }
 * error = {
 *      status: false,
 *      result:
 *          1 = missing paramters
 *          2 = malformed UEN number
 *          3 = failed to retrieve entity information using UEN number
 *          4 = entity found by UEN is not in the supported industry
 *          5 = entity not found by UEN
 * }
 * @returns {*}
 */
export function validateUEN (countryCode, uen, callback) {
    var self = this;
    if (general.isEmpty(countryCode) || general.isEmpty(uen)) {
        return callback(false, 1);
    }
    switch (countryCode) {
        default:
        case 'SG':
            if (general.cmpBool(validateUENLocally(uen), false)) {
                return callback(false, 2)
            }
            // get entity information from server
            var query = {
                business_registration_number: uen
            };
            CompanyModel.getCompanyInfoSGP(query, function (data) {
                var apiRes = api.handleApiResponse(data, false);
                if (apiRes.status === 'ok') {
                    general.log(apiRes.data);
                    if (general.cmpBool(apiRes.data.is_entity_found, false)) {
                        return callback(true, 5);
                    } else if (general.cmpBool(apiRes.data.is_supported, false)) {
                        return callback(false, 4);
                    }
                    var records = (apiRes.data.records && apiRes.data.records[0]) || {};
                    var companyName = records.entity_name || '';
                    var companyUrlPrefix = company.generateCompanyUrl(companyName);
                    var companyUrlName = company.getCompanyUrl(companyUrlPrefix);
                    // check to ensure that there are no duplicate company urls
                    self.validateHomeUrl(companyUrlPrefix, function (suggestedUrl) {
                        companyUrlName = suggestedUrl;
                        callback(true, {
                            entity: records,
                            company_name: companyName,
                            company_url_name: companyUrlName
                        });
                    });
                } else {
                    callback(false, 3);
                }
            });
            break;
    }
}

/**
 * validate share allocation
 * @param t
 * @param shareValue
 * @param shareCount
 * @param shareLeft
 * @returns {string}
 */
export function validateShareAllocation (t, shareValue, shareOriginalValue, shareCount, shareLeft) {
    // check that share value is not empty
    if (general.isEmpty(shareValue)) {
        return t('incorporation.shareholders.fields.share_count.required');
    }
    // check that share value is a valid number
    if (isNaN(shareValue) || (shareValue % 1 !== 0)) {
        return t('incorporation.shareholders.fields.share_count.invalid');
    }
    // parse integers for new share value, total share count, share left
    shareValue = parseInt(shareValue, 10);
    shareOriginalValue = parseInt(shareOriginalValue, 10); //The original shares that the individual was allocated, in the scenario where we are doing the edit of shareholder's shares
    shareCount = parseInt(shareCount, 10);
    shareLeft = parseInt(shareLeft, 10);
    // check that all values are not empty
    if (general.cmpInt(shareValue, 0) && general.cmpInt(shareCount, 0) && general.cmpInt(shareLeft, 0)) {
        return t('incorporation.shareholders.fields.share_count.required');
    }

    // check that at least 1 share is allocated for shareholder
    if (general.cmpInt(shareValue, 0)) {
        return t('incorporation.shareholders.fields.share_count.at_least_1_share');
    }
    // check that new share value doesn't exceed total number of shares
    if (shareValue > shareCount) {
        return t('incorporation.shareholders.fields.share_count.new_share_exceed_total_shares', { shareCount, shareCountPural: general.formatPural('share', shareCount) });
        // return 'Share allocation cannot exceed ' + shareCount + ' ' + general.formatPural('share', shareCount) + ' (total number of ' + general.formatPural('share', shareCount) + ')';
    }
    // check that new share value doesn't exceed total allocatable shares
    if (shareLeft > 0 && shareValue > (shareLeft + shareOriginalValue)) {
        return t('incorporation.shareholders.fields.share_count.new_share_exceed_total_allocatable_shares', { shareLeft, shareLeftPural: general.formatPural('share', shareLeft) });
        // return 'Share allocation cannot exceed ' + shareLeft + ' ' + general.formatPural('share', shareLeft) + ' (number of ' + general.formatPural('share', shareLeft) + ' left)';
    }
    return '';
}
/**
 * check if variable is a float
 * @param value
 * @returns {boolean}
 */
export function isVarFloat (value) {
    return general.cmpStr(String(parseFloat(value)), String(value));
}
